export const executiveBoard = [
  {
    name: 'Isaac Wong',
    title: 'President',
    fileName: 'isaac',
  },
  {
    name: 'Jasmyn Pillay',
    title: 'Vice President',
    fileName: 'jasmyn',
  },
  {
    name: 'Vicky Li',
    title: 'Head of Communications',
    fileName: 'vicky',
  },
  {
    name: 'Dexter Park',
    title: 'Head of Mechanical',
    fileName: 'dexter',
  },
  {
    name: 'Adriana Vargas',
    title: 'Head of Electrical',
    fileName: 'adriana',
  },
  {
    name: 'Freddy Yu',
    title: 'Co-Head of Programming',
    fileName: 'freddy',
  },
  {
    name: 'Farhan Jamil',
    title: 'Co-Head of Programming',
    fileName: 'farhan',
  },
  {
    name: 'Srinath Neelamagam',
    title: 'Co-Head of Programming',
    fileName: 'srinath',
  },
  {
    name: 'Angela Liu',
    title: 'Head of Design',
    fileName: 'angela',
  },
  {
    name: 'Vidhi Patel',
    title: 'Co-Head of CNC',
    fileName: 'vidhi',
  },
  {
    name: 'Vaibhvi Patel',
    title: 'Co-Head of CNC',
    fileName: 'vaibhvi',
  },
  {
    name: 'Eugene Zhang',
    title: 'Head of Scouting',
    fileName: 'eugene',
  },
  {
    name: 'Ava Jack',
    title: "Head of FIRST Impact",
    fileName: 'ava',
  },
  {
    name: 'Jingyi Pang',
    title: 'Head of Media',
    fileName: 'jingyi',
  },
];